import { faArrowCircleDown, faArrowDown, faCaretDown, faCaretUp, faChartArea, faChartPie, faChevronDown, faChevronUp, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LogoSimPkkHeader } from 'assets/images';
import { routesManajemenMenu, routesMonirotingMenu } from 'config/routes';
import MegaMenu from 'layout-components/MegaMenu';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, UncontrolledDropdown } from 'reactstrap';

const HeaderDashboardPublic = () => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    
    return (
      <Fragment>
        <div 
        className='sticky-top'
        style={{
            backgroundColor: "#FFFFFF",
            border: modal ? "none" : "1px solid #f5f5f5",
            boxShadow: modal ? "" : "0 1px 5px 0 rgb(0 0 0 / 10%), 0 1px 10px 0 rgb(0 0 0 / 6%)"
        }}>
            <div className="app-header container-fluid" >
            <div className="app-header--pane">
                <Link to="/dashboard-public">
                    <img src={LogoSimPkkHeader} />
                </Link>
            </div>
            <div className="app-header--pane d-none d-md-flex d-lg-flex">
                <Nav>
                    <NavItem >
                        <Link to="/peta-sebaran" className='nav-link'>
                           Peta Sebaran
                        </Link>
                    </NavItem>
                    <NavItem >
                        <Link onClick={toggle} className='nav-link'>
                           Topik Data
                           <FontAwesomeIcon className='ml-2' icon={modal ? faCaretUp : faCaretDown} />
                        </Link>
                    </NavItem>
                    <UncontrolledDropdown
                        inNavbar
                        nav
                        >
                        <DropdownToggle
                            caret
                            nav
                        >
                            Manajemen
                        </DropdownToggle>
                        <DropdownMenu right>
                            {
                                routesManajemenMenu.map((val) => {
                                    return <DropdownItem disabled={val?.isDisabled}>
                                            <a href={val.path} target="_blank">
                                                {val.name}
                                            </a>
                                        </DropdownItem>
                                })
                            } 
                            
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown
                        inNavbar
                        nav
                        >
                        <DropdownToggle
                            caret
                            nav
                        >
                            Monitoring
                        </DropdownToggle>
                        <DropdownMenu right>
                            {
                                routesMonirotingMenu.map((val) => {
                                    return <DropdownItem disabled={val?.isDisabled}>
                                            <a href={val.path} target="_blank">
                                                {val.name}
                                            </a>
                                        </DropdownItem>
                                })
                            } 
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
                <a href='https://carik.jakarta.go.id/dashboard/v2/' target="_blank">
                    <Button className="dashboard-button">
                        <FontAwesomeIcon icon={faChartPie} className="mr-2" /> Dashboard Keluarga Jakarta 
                    </Button>
                </a>
                <a href="https://simpkk.jakarta.go.id/satudata/#/auth/signin" target="_blank">
                    <Button className="login-button">
                        Masuk <FontAwesomeIcon icon={faUnlock} className="ml-2" />
                    </Button>
                </a>
            </div>
            </div>
        </div>
        <MegaMenu isOpen={modal} setModal={setModal}/>
      </Fragment>
    );
}

export default HeaderDashboardPublic;
